import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import MapLocation from 'component/common/Map/MapLocation'
import { getPacakge, checkIfLocationUpdateAllow } from 'utils/packageHelper'
import { updatePackage } from 'features/packages/packagesAction'

const isValidCoordinate = (coord) => !isNaN(parseFloat(coord)) && isFinite(coord);

export const UpdatePackageLocation = ({ id }) => {
    const dispatch = useDispatch();

    const { packages } = useSelector(
        (state) => state?.packages
    )
    const { user } = useSelector(
        (state) => state?.auth
    )
    const parcel = getPacakge(packages, id)
    const userDC = user?.distributionCenterResponseVm
    const allowEdit = checkIfLocationUpdateAllow(parcel)
    const [position, setPosition] = useState({
        lat: isValidCoordinate(parcel?.lat) ? parseFloat(parcel?.lat) : userDC?.dcLat,
        lon: isValidCoordinate(parcel?.lon) ? parseFloat(parcel?.lon) : userDC?.dcLon,
    });

    const handleMarkerDrop = ({ lat, lng }) => {
        if (isValidCoordinate(lat) && isValidCoordinate(lng)) {
            setPosition({ lat: parseFloat(lat), lon: parseFloat(lng) });
            // To Do - Call api from here
            const params = {
                ...parcel,
                //lat : lat?.toFixed(5),
                lat: lat.toString().includes('.') ? lat.toString().split('.')[0] + '.' + lat.toString().split('.')[1].slice(0, 5) : lat.toString(),
                lon: lng.toString().includes('.') ? lng.toString().split('.')[0] + '.' + lng.toString().split('.')[1].slice(0, 5) : lng.toString()
            }
            dispatch(updatePackage(params))
        }
    };

    return (
        <MapLocation onMarkerDragEnd={handleMarkerDrop}
            initialLat={position?.lat}
            initialLng={position?.lon} 
            allowEdit={allowEdit}/>
    )
}