import { routeStatus, routeStopStatus, routeStatusEnum } from 'constants/routeStatus'
import { packageStatus} from 'constants/packageStatus'
import { jsPDF } from 'jspdf';
import ExcelJS from 'exceljs';
import { getDateWithTimeStamp } from 'utils/common'
import { getHeader } from 'services/authUtils'
import { environment } from "environments/environment.jsx";
import axios from 'axios';
import { getDeliveryStatusValue } from 'utils/packageHelper'

const turf = require('@turf/turf');

const apiEndpoint = environment.webservices.endpoint;

export const canEditMissingTotes = (route) => {
    return (![routeStatusEnum?.ROUTE_COMPLETE, routeStatusEnum?.SYSTEM_ROUTE_REJECTED, routeStatusEnum?.ROUTE_TERMINATED]?.includes(route?.routeWidgetVM?.dailyRouteStatus)) && (route?.routeWidgetVM?.totalTotes - (route?.routeWidgetVM?.scannedTotes + route?.routeWidgetVM?.missingToteIds))
}

export const getRouteTreeData = (data) => {
    const treeData = [];

    data?.forEach(parent => {
        const parentItem = {
            itemId: parent?.dailyRouteScanSummaryVM.routeId?.toString(),
            label: parent?.dailyRouteScanSummaryVM?.routeName,
            status: parent?.dailyRouteScanSummaryVM?.dailyRouteStatus,
            children: [],
            options: routeStatus,
            type: 'Route'
        };

        parent?.routeStops?.forEach(stop => {
            const stopItem = {
                itemId: stop?.routeStopId.toString(),
                label: stop?.routeStopAddress,
                status: stop?.routeStopStatus,
                children: [],
                options: routeStopStatus,
                type: 'RouteStop'
            };

            stop?.packageDetails?.forEach(packageDetail => {
                const packageItem = {
                    itemId: packageDetail?.trackingNumber,
                    label: packageDetail?.trackingNumber,
                    status: packageDetail?.packageStatus,
                    options: packageStatus,
                    type: 'Parcel'
                };
                stopItem.children.push(packageItem);
            });

            parentItem.children.push(stopItem);
        });

        treeData.push(parentItem);
    });

    return treeData;
}


const fetchRouteStopsData = async (route) => {
    const routeResponse = await axios.get(`${apiEndpoint}/api/daily-routes/${route?.routeWidgetVM?.routeId}`, {
        headers: getHeader()
    });
    let routeStops = routeResponse?.data?.routeStops?.sort((a, b) => a?.routeStopSequence - b?.routeStopSequence)
    return routeStops;
}


// Define columns for route details
const routeHeaders = [
    'Route Name', 'Total Totes', 'Total Stops', 'Total Orders',
    'Driver Name', 'Driver ID', 'Primary FSA Zone', 'Route Date and Time'
];

const parcelHeaders = ['Stop Sequence No', 'Tracking Number', 'Consignee Name', 'Address 1', 'Address 2', 'City', 'Province', 'Postal Code', 'P/U quantity', 'ETA', 'Special Instructions', 'Internal Comments', 'Attribute'];

const getRouteRowDetails = (route, user) => {
    return [
        route?.routeWidgetVM?.routeName,
        route?.routeWidgetVM?.totalTotes,
        route?.routeWidgetVM?.totalStops,
        route?.routeWidgetVM?.totalPackages,
        route?.routeWidgetVM?.driverName,
        route?.routeWidgetVM?.driverId || null,
        route?.routeWidgetVM?.primaryFsaZone,
        route?.routeWidgetVM?.routeStartDate ? getDateWithTimeStamp(route?.routeWidgetVM?.routeStartDate, user) : ''
    ]
}

const getParcelDetails = (routeStop, parcel, user) => {
    return [
        routeStop?.routeStopSequence,
        parcel?.trackingNumber,
        parcel?.consignee,
        parcel?.addressOne,
        parcel?.addressTwo,
        parcel?.city,
        parcel?.province,
        parcel?.postalCode,
        parcel?.puQuantity,
        getDateWithTimeStamp(routeStop?.estimatedArrival, user, true),
        parcel?.specialInstructions || '',
        parcel?.internalComment || '',
        getDeliveryStatusValue(parcel?.highPriority, parcel?.sla, parcel?.highValueItem) || ''
    ]
}

export const handleExportRouteClick = async (route, user) => {
    try {
        const routeStops = await fetchRouteStopsData(route)

        // Create a new workbook
        const workbook = new ExcelJS.Workbook();

        // Add a worksheet for combined route details and parcel data
        const worksheet = workbook.addWorksheet('Route Data');

        const routeHeaderRow = worksheet.addRow(routeHeaders);
        routeHeaderRow.eachCell((cell) => {
            cell.font = { bold: true };
        });

        // Add route summary data to the worksheet
        worksheet.addRow(getRouteRowDetails(route, user));

        // Add a blank row
        worksheet.addRow({});

        // Add a header for parcel data
        const parcelHeaderRow = worksheet.addRow(parcelHeaders);

        parcelHeaderRow.eachCell((cell) => {
            cell.font = { bold: true };
        });

        // Populate parcel data
        routeStops.forEach(routeStop => {
            routeStop.packageDetails.forEach(parcel => {
                worksheet.addRow(getParcelDetails(routeStop, parcel, user));
            });
        });

        // Generate the Excel file as a blob
        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            // Create a download link
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'Route.xlsx';
            a.click();
            window.URL.revokeObjectURL(url);
        });

    } catch (error) {
        console.error('Error exporting data:', error);
    }
};

export const getInductionBarColor = () => {
    return {
        color: '#5D48FF',
        backgroundImage: 'repeating-linear-gradient(45deg, transparent, transparent 5px, #ffffff 5px, #ffffff 10px)'
    }

}

export const totalParcelInTote = (totalPackagesInTotes) => {
    if (totalPackagesInTotes && Object.keys(totalPackagesInTotes).length > 0) {
        return Object.entries(totalPackagesInTotes)
            .map(([toteId, count]) => `${toteId}: ${count}`)
            .join(' | ');
    }
    return '--';
};

export const handleExportToPDFClick = async (route, user) => {
    try {
        const routeStops = await fetchRouteStopsData(route)

        const doc = new jsPDF({
            orientation: 'landscape',
            unit: 'mm',
            format: 'a4'
        });

        // First table data
        const routeTableRows = [
            getRouteRowDetails(route, user),
        ];

        function formatDataForTable(routeStops) {
            const tableRows = [];
            routeStops.forEach(routeStop => {
                routeStop.packageDetails.forEach(parcel => {
                    tableRows.push(getParcelDetails(routeStop, parcel, user));
                });
            });
            return tableRows;
        }

        // Second table data
        const parcelTableRows = formatDataForTable(routeStops);

        // First table
        doc.autoTable({
            head: [routeHeaders],
            body: routeTableRows,
            startY: 20,
            theme: 'striped'
        });

        // Second table
        doc.autoTable({
            head: [parcelHeaders],
            body: parcelTableRows,
            startY: doc.autoTable.previous.finalY + 20, //Set gap between two tables
            theme: 'striped'
        });

        // Save the PDF content as a Blob
        const pdfBlob = doc.output('blob');

        // Create a URL for the Blob
        const pdfUrl = URL.createObjectURL(pdfBlob);

        // Open the print dialog for the PDF content
        const printWindow = window.open(pdfUrl);
        if (printWindow) {
            printWindow.onload = () => {
                printWindow.print();
            };
        }

    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export const getDistanceDiff = (lat1, lat2, lon1, lon2) => {
    if (lat1 && lat2 && lon1 && lon2) {
        try {
            const point1 = turf.point([lon1, lat1]);
            const point2 = turf.point([lon2, lat2]);
            // Calculate distance between two points in meters
            const distanceInKm = parseFloat(turf.distance(point1, point2, { units: 'kilometers' }));
            // Convert to meters if the distance is less than 1 km
            if (distanceInKm < 1) {
                const distanceInMeters = (distanceInKm * 1000)?.toFixed(2);
                return `${distanceInMeters} meters`;
            }
            return `${distanceInKm?.toFixed(2)} km`;
        }
        catch (e) {
            console.log('Exception', e)
        }
    }
}
export const getRouteParcels = (routeStops, user) => {
    const parcels = []
    // Populate parcel data
    routeStops?.forEach(routeStop => {
        routeStop?.packageDetails?.forEach(parcel => {
            parcels.push({
                id: parcel?.packageDetailsId,
                routeStopSequence: routeStop?.routeStopSequence,
                trackingNumber: parcel?.trackingNumber,
                consignee: parcel?.consignee,
                addressOne: parcel?.addressOne,
                addressTwo: parcel?.addressTwo,
                city: parcel?.city,
                province: parcel?.province,
                postalCode: parcel?.postalCode,
                addressGeocode: `${parcel?.lat}, ${parcel?.lon}`,
                serviceAttemptGeoCode: `${parcel?.serviceAttemptLat}, ${parcel?.serviceAttemptLon}`,
                serviceAttemptDelta: getDistanceDiff(parcel?.lat, parcel?.serviceAttemptLat, parcel?.lon, parcel?.serviceAttemptLon),
                estimatedArrival: getDateWithTimeStamp(routeStop?.estimatedArrival, user, true),
                clientName: parcel?.bussinessName,
                specialInstructions: parcel?.specialInstructions,
                internalComment: parcel?.internalComment,
                highPriority: parcel?.highPriority,
                sla: parcel?.sla,
                highValueItem: parcel?.highValueItem,
                actualArrivalTime: getDateWithTimeStamp(routeStop?.actualArrivalTime, user, true),
                pathFinderScanTime: getDateWithTimeStamp(parcel?.pathFinderScanDateTime, user),
                ringScannerScanTime: getDateWithTimeStamp(parcel?.ringScannerScanDateTime, user),
                ringScannerPriorShortScanTime :  getDateWithTimeStamp(parcel?.ringScannerPriorShortScanTime, user),
                pathFinderPriorShortScanTime :  getDateWithTimeStamp(parcel?.pathFinderPriorShortScanTime, user),
                packageStatus: parcel?.packageStatus
            })
        });
    });
    return parcels

}

export const getRouteSummary = (routeSummary, widget) => {
    if (widget === 'BATCHES') {
        return {
            'ACCEPT': routeSummary?.dailyRouteStatusCount?.['ACCEPT'] ?? 0,
            'REVERTED': routeSummary?.dailyRouteStatusCount?.['REVERTED ROUTES'] ?? 0,
            'FINAISE': routeSummary?.dailyRouteStatusCount?.['FINALIZE'] ?? 0,
            'IN_PROGRESS': routeSummary?.dailyRouteStatusCount?.['IN PROGRESS'] ?? 0,
            'REJECT': routeSummary?.dailyRouteStatusCount?.['REJECT'] ?? 0,
            'PACKAGE_TO_BATCH': `Batch Ready : ${routeSummary?.dailyRouteStatusCount?.ORDER_BATCH_READY ?? 0}/ Batch Re-ready :  ${routeSummary?.dailyRouteStatusCount?.ORDER_REBATCH_READY ?? 0}/ Shortage : ${routeSummary?.dailyRouteStatusCount?.SHORTAGE ?? 0}`
        }
    }
    else return [{
        key: 'Route Created',
        value: routeSummary?.dailyRouteStatusCount?.[routeStatusEnum?.ROUTE_CREATED]
    },
    {
        key: 'Route Assigned',
        value: routeSummary?.dailyRouteStatusCount?.[routeStatusEnum?.ROUTE_ASSIGNED]
    },
    {
        key: 'Driver Accepted',
        value: routeSummary?.dailyRouteStatusCount?.[routeStatusEnum?.DRIVER_ACCEPTED]
    },
    {
        key: 'Driver Declined',
        value: routeSummary?.dailyRouteStatusCount?.[routeStatusEnum?.DRIVER_DECLINED]
    },
    {
        key: 'Route Scanned',
        value: routeSummary?.dailyRouteStatusCount?.[routeStatusEnum?.ROUTE_SCANNED]
    },
    {
        key: 'Route Loaded',
        value: routeSummary?.dailyRouteStatusCount?.[routeStatusEnum?.ROUTE_LOADED]
    },
    {
        key: 'Route Started',
        value: routeSummary?.dailyRouteStatusCount?.[routeStatusEnum?.ROUTE_STARTED]
    },
    {
        key: 'Route Complete',
        value: routeSummary?.dailyRouteStatusCount?.[routeStatusEnum?.ROUTE_COMPLETE]
    },
    {
        key: 'Route Halted',
        value: routeSummary?.dailyRouteStatusCount?.[routeStatusEnum?.ROUTE_HALTED]
    },
    {
        key: 'Route Breakdown',
        value: routeSummary?.dailyRouteStatusCount?.[routeStatusEnum?.ROUTE_BREAKDOWN]
    },
    {
        key: 'Rescue Route Success',
        value: routeSummary?.dailyRouteStatusCount?.[routeStatusEnum?.RESCUE_ROUTE_SUCCESS]
    },
    {
        key: 'System Route Rejected',
        value: routeSummary?.dailyRouteStatusCount?.[routeStatusEnum?.SYSTEM_ROUTE_REJECTED]
    },
    {
        key: 'Route on break',
        value: routeSummary?.dailyRouteStatusCount?.[routeStatusEnum?.ROUTE_ON_BREAK]
    },
    {
        key: 'Terminated Route',
        value: routeSummary?.dailyRouteStatusCount?.[routeStatusEnum?.ROUTE_TERMINATED]
    }
    ]
}

const canPerfromMissingTote = [routeStatusEnum?.ROUTE_CREATED, routeStatusEnum?.ROUTE_ASSIGNED, routeStatusEnum?.ROUTE_SCANNED ]

export const canPerformRouteOperation = (route, operation) => {
    const status = route?.routeWidgetVM?.dailyRouteStatus || route?.dailyRouteScanSummaryVM?.dailyRouteStatus;

    switch (operation) {
        case 'BREAK_DOWN':
            return status === routeStatusEnum?.ROUTE_STARTED;
        case 'RESCHEDULE':
            return status === routeStatusEnum?.ROUTE_CREATED;
        case 'TERMINATE':
            return status === routeStatusEnum?.ROUTE_STARTED;
        case 'RESCUE':
            return status === routeStatusEnum?.ROUTE_BREAKDOWN;
        case 'SPLIT':
            return status === routeStatusEnum?.ROUTE_CREATED && route?.routeWidgetVM?.totalPackages > 1 && route?.routeWidgetVM?.totalTotes > 1;
        case 'MISSING_TOTE':
            return canPerfromMissingTote.includes(status) 
        default:
            return false;
    }
};
