import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Radio, RadioGroup, FormControlLabel, FormControl } from '@mui/material';
import { getRoute } from 'features/route/routeAction'
import './routeDetailsComponent.scss'
import { RouteMapComponent } from './routeMapComponent';
import { RouteParcels } from './routeParcel'
import { PrimaryButton } from 'component/common/Button/Button'
import { canPerformRouteOperation } from 'utils/routeHelper'
import { RescueRouteModal } from './RescueRouteModal';
import { getRouteParcels } from 'utils/routeHelper'
import './routeDetailsComponent.scss'

const HEIGHT = '24rem'

export const RouteDetails = React.memo(({ id }) => {
    const dispatch = useDispatch();
    const { data: routeData, routeSuccess } = useSelector(
        (state) => state.route
    )
    const { user } = useSelector(
        (state) => state?.auth
    )

    const [selectedOption, handleOptionChange] = useState('map')
    const [isRouteRescueModal, setIsRouteRescueModal] = useState(false)
    const [selectedParcels, setSelectedParcels] = useState([])
    const [resetSelectedRows, setResetSelectedRows] = useState(false)

    const routeDetail = routeData[id]
    const data = getRouteParcels(routeDetail?.routeStops, user)

    const handleRescueRouteClick = () => {
        setIsRouteRescueModal(!isRouteRescueModal)
    }

    const handleParcelSelect = (parcels) => {
        setSelectedParcels(parcels);
    };

    useEffect(() => {
        dispatch(getRoute(id))
    }, [])


    useEffect(() => {
        if (routeSuccess) {
            setResetSelectedRows(true)
            const redirectTimer = setTimeout(() => {
                setIsRouteRescueModal(false)
                setSelectedParcels([])
                setResetSelectedRows(false)
            }, 1000);
            return () => {
                clearTimeout(redirectTimer);
            }
        }
    }, [routeSuccess])

    useEffect(() => {
        // this is to fetch data if user refresh page
        if (Object.keys(routeData)?.length === 0) {
            dispatch(getRoute(id))
        }
    }, [routeData])

    return (
        <>
            <FormControl component="fieldset" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: 'fit-content' }}>
                <RadioGroup
                    row
                    aria-label="options"
                    name="options"
                    value={selectedOption}
                    onChange={(event) => handleOptionChange(event.target.value)}
                >
                    <FormControlLabel
                        key={'map'}
                        value={'map'}
                        control={<Radio />}
                        label={<span className='text'>Show Map</span>}
                    />
                    <FormControlLabel
                        key={'table'}
                        value={'table'}
                        control={<Radio />}
                        label={<span className='text'>Show parcels</span>}
                    />
                </RadioGroup>

                {canPerformRouteOperation(routeDetail, 'RESCUE') && selectedOption === 'table' ?
                    <PrimaryButton type="button" label={'Create Rescue Route'} onClick={handleRescueRouteClick} height={'30px'} disabled={!selectedParcels?.length} /> : null
                }
            </FormControl>
            {selectedOption === 'map' ?
                <RouteMapComponent routeDetail={routeDetail} height={HEIGHT}/>
                : <RouteParcels routeDetail={routeDetail} height={HEIGHT} handleParcelSelect={handleParcelSelect} data={data} resetSelectedRows={resetSelectedRows} />}
            {isRouteRescueModal ? <RescueRouteModal isModelOpen={isRouteRescueModal} user={user} handleClose={handleRescueRouteClick} route={routeDetail} handleParcelSelect={handleParcelSelect} parcels={selectedParcels} data={data} /> : null}

        </>
    )
})