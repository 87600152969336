import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import DataTable from 'component/dataTable/dataTable'
import { ResponseModal } from 'component/common/Modal/ResponseModal'
import { Loader } from 'component/common/Loader/Loader'
import { Link } from '@mui/material';
import { iconStyle, covertToString, getDateWithTimeStamp, getConstantValue } from 'utils/common'
import { togglePackageSelection } from 'features/packages/packagesSlice'
import InfoIcon from '@mui/icons-material/Info';
import { setAuditData } from 'features/audit/auditSlice'
import { Audit } from 'component/audit/audit'
import { Tooltip } from '@mui/material';
import { packageStatus } from 'constants/packageStatus';
import PackageAddressAccordian from './packageAddressAccordian'
import { SpecialInstrucionsCellRenderer } from 'component/packages/SpecialInstructionsCellRenderer'
import { urls } from 'utils/urls'
const rowKey = "id"

export const getTrackingNumbers = (selectedPackages, parcels) => {
    const selectedPackageSet = new Set(selectedPackages);
    const trackingIds = parcels?.filter((parcel) => selectedPackageSet?.has(String(parcel.id)))
        .map((parcel) => parcel.trackingNumber);
    return trackingIds;
};

export const ScannedItems = ({ parcels, routeId }) => {
    const dispatch = useDispatch();
    const { loading, updatePackageSuccess } = useSelector(
        (state) => state?.packages
    )
    const { isMobile } = useSelector(
        (state) => state?.viewport
    )
    const { user } = useSelector(
        (state) => state?.auth
    )
    const navigate = useNavigate();
    const [modalProps, setModalProps] = useState(null)
    const [resetSelectedRows, setResetSelectedRows] = useState(false)

    const trackPackagestatus = (id) => {
        navigate(`${urls?.PARCEL_TRACKING_WIDGET_URL}?tracker-id=${id}`)
    }

    const handlePackageSelect = (selectedPackages) => {
        const trackingNumbers = getTrackingNumbers(selectedPackages, parcels);
        dispatch(togglePackageSelection({ routeId: routeId, packageIds: trackingNumbers }))
    }

    useEffect(() => {
        if (updatePackageSuccess) {
            setResetSelectedRows(true)
            const redirectTimer = setTimeout(() => {
                setResetSelectedRows(false)
            }, 1000);
            return () => {
                clearTimeout(redirectTimer);
            }
        }
    }, [updatePackageSuccess]);


    const columns = [
        {
            accessor: "trackingNumber",
            title: "Tracking Number",
            width: "4rem",
            Cell: ({ cell: { value } }) => <Link id='trackingNumber' style={{ cursor: 'pointer', iconStyle }} sx={{ textDecoration: 'none' }} onClick={() => trackPackagestatus(value)}> {value}</Link > || '--',
            render: (item) => item?.trackingNumber || '--'
        },
        {
            accessor: "clientName",
            title: "Client Name",
            width: "3rem",
            Cell: ({ cell: { value } }) => value || '--',
            render: (item) => item?.clientName || '--'
        },
        {
            accessor: "returnDate",
            title: "Return Date",
            width: isMobile ? '6rem' : "3rem",
            Cell: ({ cell: { value } }) => value ? getDateWithTimeStamp(value, user) : '--',
            render: (item) => item?.returnDate ? getDateWithTimeStamp(item?.returnDate, user) : '--'
        },
        {
            accessor: "deliveryAttemptStatus",
            title: "Delivery Attempt Status",
            width: "5rem",
            Cell: ({ cell: { value } }) => value ? getConstantValue(packageStatus, value) : '--',
            render: (item) => item?.deliveryAttemptStatus ? getConstantValue(packageStatus, item?.deliveryAttemptStatus) : '--'
        },
        {
            accessor: "returnsStatus",
            title: "Returns Status",
            width: "5rem",
            Cell: ({ cell: { value } }) => value ? getConstantValue(packageStatus, value) : '--',
            render: (item) => item?.returnsStatus ? getConstantValue(packageStatus, item?.returnsStatus) : '--'
        },
        {
            accessor: "numberOfDeliveryAttempt",
            title: "Delivery Attempt",
            width: isMobile ? '4rem' : '2rem',
            Cell: ({ cell: { value } }) => value,
            render: (item) => item?.numberOfDeliveryAttempt
        },
        {
            accessor: "driverComment",
            title: "Driver Comment",
            width: "5rem",
            Cell: ({ cell: { value } }) => value || '--',
            render: (item) => item?.reason || '--'
        },
        {
            accessor: "specialInstructions",
            title: "Special Instructions",
            width: "5rem",
            Cell: ({ cell: { row: { original } } }) => original?.specialInstructions ? <SpecialInstrucionsCellRenderer data={original} allowEdit={true} type={'specialInstructions'} /> : '--',
            render: (item) => item?.specialInstructions || '--'
        },
        {
            accessor: "internalComment",
            title: "Internal Comments",
            width: "5rem",
            Cell: ({ cell: { row: { original } } }) => <SpecialInstrucionsCellRenderer data={original} allowEdit={true} type={'internalComments'} />,
            render: (item) => item?.internalComment || '--'
        },
        {
            //Track event here
            accessor: "deliveryDate",
            title: "Reschedule Delivery",
            width: isMobile ? '6rem' : "3rem",
            Cell: ({ cell: { row: { original } } }) => original?.deliveryDate ? getDateWithTimeStamp(original?.deliveryDate, user) : '--',
            render: (item) => item?.deliveryDate ? getDateWithTimeStamp(item?.deliveryDate, user) : '--'
        },
        {
            accessor: 'action',
            title: "Action",
            width: isMobile ? '3rem' : "1rem",
            Cell: ({ cell: { row: { original } } }) => <div className='action' >
                <Tooltip title="Info"><InfoIcon style={iconStyle} fontSize="small" onClick={() => dispatch(setAuditData({ 'entityId': original?.trackingNumber, 'entityType': 'package' }))} /></Tooltip>
            </div>
        }
    ]?.filter(Boolean)

    return (
        <>
            <div className='content'>
                <DataTable dataTestId={'returned-parcels'} columns={columns} data={parcels} isCollpsable={true} showPagination={false} uniqueKey={rowKey}
                    childComponent={PackageAddressAccordian} rowKey={rowKey} isChildComponentExpanded={true} maxHeight={'inherit'} allowSelect={true}
                    onSelectClick={handlePackageSelect} resetSelectedRows={resetSelectedRows}
                />
            </div>
            {modalProps ? <ResponseModal {...modalProps} handleClose={() => setModalProps(null)} /> : null}
            {loading ? <Loader isLoading={loading} /> : null}
            <Audit />
        </>
    )
}