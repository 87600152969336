import React, { useState, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { getRoute, getRouteSequence } from 'features/route/routeAction'
import './routeDetailsComponent.scss'
import { PrimaryButton } from 'component/common/Button/Button'
import './routeDetailsComponent.scss'
import { Layout } from 'component/common/Layout/Layout';
import { TextField } from "component/common/Input/TextField";
import 'component/userManagement/userAccount/UserAccount.scss'
import { LiveTrackingMap } from "component/liveTracking/liveTrackingMap1";
import './routeDetailsComponent.scss'

export const FullHeightMap = React.memo(() => {
    const dispatch = useDispatch();
    const { data: routeData, sequenceData: routeSequenceData } = useSelector(
        (state) => state.route
    )

    const [routeId, setRouteId] = useState(null)

    const routeDetail = routeData[routeId]
    const sequenceDetail = routeSequenceData[routeId]

    const handleChange = (event) => {
        setRouteId(event.target.value);
    }

    const handleButtonClick = () => {
        dispatch(getRoute(routeId))
        dispatch(getRouteSequence(routeId))
    }

    //const center = { dcLat: routeDetail?.distributionCenterResponseVm?.dcLat, dcLon: routeDetail?.distributionCenterResponseVm?.dcLon }

        //Using momoize component to render map, as map will through error if we render on every state change
        const memoizedComponent = useMemo(() => {
            const center = { dcLat: routeDetail?.distributionCenterResponseVm?.dcLat, dcLon: routeDetail?.distributionCenterResponseVm?.dcLon }
            return <LiveTrackingMap height={'50rem'} showStops={true} routeStops={routeDetail?.routeStops || []} center={center} sequenceDetail={sequenceDetail}/>
        }, [routeDetail, sequenceDetail])

    return (
        <Layout headerTitle={'Sequence'} showBackArrow={false}>
            <div className='container'>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    margin: '1rem'
                }}>
                    <TextField
                        variant="outlined"
                        defaultValue=""
                        onChange={handleChange}
                    />
                    <PrimaryButton
                        type="button"
                        label={'Submit'}
                        onClick={handleButtonClick}
                        height={'30px'}
                        style={{ marginLeft: '10px' }} // Adjust the spacing
                    />
                </div>
                <div className='route-detail-container' >
                    {memoizedComponent}
                    {/* <LiveTrackingMap sequenceDetail={sequenceDetail} height={'50rem'} showStops={true} routeStops={routeDetail?.routeStops || []} center={center} /> */}
                </div>
            </div>
        </Layout >
    )
})