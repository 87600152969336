import { createSlice } from '@reduxjs/toolkit';
import { fetchBatches, movePackagesToBatch, createBatch, resequenceBatch, fetchBatchPackageDetails, updateBatch, fetchFSAZonesForBatches, destroyBatch, generateRoute } from './batchAction';
import { logout } from 'features/auth/authAction'

const colors = ['red', 'orange', 'blue', 'green', 'purple']

const addColorToBatches = (batchesData) => {
  return batchesData.map((batch, index) => {
    const colorIndex = index % colors.length;
    const color = colors[colorIndex];
    return { ...batch, color };
  });
};

const sortBatches = (batchesData) => {
  return batchesData?.sort((a, b) => new Date(b?.createDate) - new Date(a?.createDate));
};

const initialState = {
  data: null,
  loading: false,
  error: null,
  batches: [],
  fsaZones: [],
  batchSuccess: false,
  updateBatchSuccess: false,
  bacthPackageDetails: null,
  fsaZoneBatchesLoading: false,
  batchCount: 0,
  successMessage: null
}

const batchSlice = createSlice({
  name: 'batch',
  initialState,
  reducers: {
    setPackageFilter: (state, action) => {
      const updatedPackageFilter = {
        ...state.packageFilter,
        ...action.payload
      };
      state.packageFilter = updatedPackageFilter;
    },
    resetSuccessState: (state) => {
      state.updateBatchSuccess = false;
      state.bacthPackageDetails = null;
      state.batchSuccess = false;
      state.successMessage = null;
      state.error=null
    },
    resetBatches: (state) => {
      state.batches = [];
    }
  },
  extraReducers: (builder) => {
    builder
      // All batches
      .addCase(fetchBatches.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBatches.fulfilled, (state, action) => {
        state.loading = false;
        state.batches = sortBatches(action.payload?.batches);
        state.batchCount = action?.payload?.totalCount
      })
      .addCase(fetchBatches.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Crate Batch
      .addCase(createBatch.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createBatch.fulfilled, (state, action) => {
        /* This is needed because now same action can be perfoemd
        from batch map view and different api needs to be call based on success state */
        const methodType = action?.payload?.methodType
        const isBatchMapView = action?.payload?.isBatchMapView

        state.loading = false;
        if (methodType === 'put' && isBatchMapView) {
          state.updateBatchSuccess = true
        }
        else {
          state.batchSuccess = true
          state.successMessage = 'Creating batch may take some time. Please refresh page after some time';
        }
      })
      .addCase(createBatch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Move packages to batch
      .addCase(movePackagesToBatch.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(movePackagesToBatch.fulfilled, (state, action) => {
        const isBatchMapView = action?.payload?.isBatchMapView
        state.loading = false;
        if(isBatchMapView){
          state.updateBatchSuccess = true
        }
        else {
          state.batchSuccess = true
        }
      })
      .addCase(movePackagesToBatch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Fetch batches with package details
      .addCase(fetchBatchPackageDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBatchPackageDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.bacthPackageDetails = addColorToBatches(action?.payload)
      })
      .addCase(fetchBatchPackageDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // updateBatch batch  - Accept, reject, 
      .addCase(updateBatch.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateBatch.fulfilled, (state, action) => {
        state.loading = false;
        state.batchSuccess = true
      })
      .addCase(updateBatch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // FSA zones for batch
      .addCase(fetchFSAZonesForBatches.pending, (state) => {
        state.fsaZoneBatchesLoading = true;
        state.error = null;
      })
      .addCase(fetchFSAZonesForBatches.fulfilled, (state, action) => {
        state.fsaZoneBatchesLoading = false;
        state.fsaZones = action?.payload
      })
      .addCase(fetchFSAZonesForBatches.rejected, (state, action) => {
        state.fsaZoneBatchesLoading = false;
        state.error = action.payload;
      })

      // Generate route from batch
      .addCase(generateRoute.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(generateRoute.fulfilled, (state,action) => {
        state.loading = false;

        if(action.payload.routeNotCreatedBatchIds){
          state.batchSuccess = false;
          const [message, batchIds] = Object.entries(action.payload.routeNotCreatedBatchIds)[0];
          state.error = `${message}: ${batchIds.join(', ')}`;
    } else {
        state.batchSuccess = true;
        state.successMessage = 'Generating route may take some time. Please refresh page after some time';
    }
      })
      .addCase(generateRoute.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Destroy batch
      .addCase(destroyBatch.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(destroyBatch.fulfilled, (state) => {
        state.loading = false;
        state.batchSuccess = true
      })
      .addCase(destroyBatch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      //resequenceBatch
      .addCase(resequenceBatch.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(resequenceBatch.fulfilled, (state) => {
        state.loading = false;
        state.batchSuccess = true
      })
      .addCase(resequenceBatch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(logout.fulfilled, (state) => {
        return initialState;
      })
  }
})
export const { resetSuccessState, resetBatches } = batchSlice.actions;
export default batchSlice.reducer;