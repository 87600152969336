import { packagesBeforeBatchStatus, packageStatusEnum, packagesInBatchEnum, packagesInRouteEnum, packagesRTDTEnum, packagesInInductionEnum, packageSuccessFullDeliveredEnum, packageUnSuccessFullDeliveryEnum } from 'constants/packageStatus'
import { roleConstantEnum } from 'constants/roleConstants'
import { getUserRole } from 'utils/common'

export const packagesWithErrorStatus = [
    packageStatusEnum?.PARSE_ERROR_MANIFEST_CLIENT, packageStatusEnum?.PARSE_ERROR_MANIFEST_STREET_PERFECT,
    packageStatusEnum?.PARSE_ERROR_MANIFEST_STREET_PERFECT_MISMATCH,
    packageStatusEnum?.DUPLICATE_MANIFEST_STREET_PERFECT, packageStatusEnum?.PROCESSED_STREET_PERFECT_MARKED_BAD,
    packageStatusEnum?.PROCESSED_STREET_PERFECT_NOT_CORRECTED, packageStatusEnum?.LAT_LON_RESOLVE_FAILED_FROM_HERE,
]

export const packagesWithRemediationStatus = [
    packageStatusEnum?.RTERMINAL_INVTGN, packageStatusEnum?.RTERMINAL_RTS_T1
]

export const packagesWithInvestigationStatus = [packageStatusEnum?.RTERMINAL_INVTGN, packageStatusEnum?.ON_HOLD_REMEDIATION, packageStatusEnum?.ARCHIVE, packageStatusEnum?.OUT_OF_SERVICE_AREA]

export const packagesWithDeliveredStatus = [
    packageStatusEnum?.PARCEL_DEL_SUCC_SD, packageStatusEnum?.PARCEL_DEL_SUCC_SD_SIDE, packageStatusEnum?.PARCEL_DEL_SUCC_SD_REAR, packageStatusEnum?.PARCEL_DEL_SUCC_SD_LOCKER, packageStatusEnum?.PARCEL_DEL_SUCC_CSGNE, packageStatusEnum?.PARCEL_DEL_SUCC_CSGNE_ADULT, packageStatusEnum?.PARCEL_DEL_SUCC_CSGNE_TP, packageStatusEnum?.PARCEL_DEL_SUCC_CSGNE_SEC, packageStatusEnum?.PARCEL_DEL_SUCC_CSGNE_CRG, packageStatusEnum?.PARCEL_DEL_SUCC_CSGNE_REC
]

export const allowChipEditStatus = [packageStatusEnum?.ORDER_BATCH_READY, packageStatusEnum?.SEQUENCED]
export const investigationStatus = [packageStatusEnum?.RTERMINAL_INVTGN]



export const canSendToInvestigation = (parcel) => {
    const isValidStatus = ![
        packageStatusEnum?.LAT_LNG_RESOLVE_SUCCESS_FROM_MN_TABLE,packagesInInductionEnum?.LOADED, packagesInRouteEnum?.TOTE_ASSIGNED, packagesInBatchEnum?.SEQUENCED, packagesRTDTEnum?.RTERMINAL_INVTGN, packagesRTDTEnum?.ORDER_BATCH_READY, packagesRTDTEnum?.ORDER_REBATCH_READY, packagesRTDTEnum?.RTERMINAL_RTS_T2, ...packagesWithDeliveredStatus, ...packagesWithInvestigationStatus, ...packagesWithErrorStatus].includes(parcel?.packageStatus);

    // Check if parcel has an unsuccessful, damaged, or missing status and daily route is not null
    const isUnsuccessfulStatusWithRoute = [...Object.values(packageUnSuccessFullDeliveryEnum)].includes(parcel?.packageStatus) && parcel?.routeId !== null;

    return isValidStatus && !isUnsuccessfulStatusWithRoute;
}

export const getPackageIds = (packages) => {
    return packages.reduce((acc, curr) => {
        return acc.concat(curr.packageIds);
    }, []);
};

export const canEditPackage = (packageStatus) => {
    return !['LOADED', ...packagesWithDeliveredStatus]?.includes(packageStatus)
}

//For other users edit will be allowed only till batch ready for CSR it will include 2 more status
export const allowPackageEdit = (packageStatus, user) => {
    const holdAndArchieve = [packageStatusEnum?.ARCHIVE, packageStatusEnum?.ON_HOLD_REMEDIATION, packageStatusEnum?.OUT_OF_SERVICE_AREA]
    const userRole = getUserRole(user)
    if (userRole?.includes(roleConstantEnum?.ROLE_CSR)) {
        const allowedStatuses = [...packagesWithErrorStatus, ...packagesWithRemediationStatus, ...packagesWithInvestigationStatus]
        return allowedStatuses.includes(packageStatus) && !holdAndArchieve?.includes(packageStatus)
    }
    const allowedStatuses = [...packagesBeforeBatchStatus, ...packagesWithErrorStatus, ...packagesWithInvestigationStatus]
    return allowedStatuses.includes(packageStatus) && !holdAndArchieve?.includes(packageStatus)
}

export const getDeliveryStatusValue = (highPriority, sla, highValueItem, onHold, signatureOnly) => {
    let result = '';
    if (highPriority) {
        result += 'High Priority';
    }
    if (sla) {
        if (result !== '') {
            result += ', ';
        }
        result += 'SLA';
    }
    if (highValueItem) {
        if (result !== '') {
            result += ', ';
        }
        result += 'High Value';
    }
    if (onHold) {
        if (result !== '') {
            result += ', ';
        }
        result += 'On Hold';
    }
    if (signatureOnly) {
        if (result !== '') {
            result += ', ';
        }
        result += 'Signature Only';
    }
    return result;
}

export const canViewInternalComments = (user) => {
    const userRole = getUserRole(user)
    const allowedRoles = ['ROLE_ADMIN', 'ROLE_SUPERVISOR', 'ROLE_CSR']
    return allowedRoles.includes(userRole);
};


export const getPackageAddresDetails = (packageDetails) => {
    return [
        {
            key: 'Address',
            value: `${packageDetails?.addressOne} ${packageDetails?.addressTwo ? `,${packageDetails?.addressTwo}` : ''}, ${packageDetails?.city}, ${packageDetails?.province}, ${packageDetails?.postalCode}`
        },
        { key: 'FSA Sub Zone', value: packageDetails?.fsaSubZone },
        { key: 'FSA Zone', value: packageDetails?.fsaZone },
        { key: 'DC', value: packageDetails?.dcName },
        { key: 'Consignee Name', value: packageDetails?.consignee }
    ]
}

export const getPacakge = (data, id) => {
    return data?.find((item) => item?.id === id)
}

export const getRouteStopSequence = (route, trackingNumber) => {
    const stop = route?.routeStops.find(stop =>
        stop?.packageDetails?.some(parcel => parcel?.trackingNumber === trackingNumber)
    );
    return stop ? stop?.routeStopSequence : null;
}

export const getDelievyStatusColorCode = (status) => {
    if (status in packageSuccessFullDeliveredEnum) {
        return 'green';
    }
    else if (status in packageUnSuccessFullDeliveryEnum) {
        return 'red';
    }
    else if (status === packagesInRouteEnum?.DAMAGED) {
        return 'black';
    }
    else if (status === packagesInRouteEnum?.PACKAGE_MISSING) {
        return 'grey';
    }
    else {
        return '#5D48FF';
    }
}

export const canUpdateSignatureOnlyAttribute = (parcel, user) => {
    const userRole = getUserRole(user)
    return (userRole !== roleConstantEnum?.ROLE_CLIENT && !packageSuccessFullDeliveredEnum?.hasOwnProperty(parcel?.packageStatus))
}

export const checkOnHoldAndAllowEdit = (parcel, packageStatusEnum, user) => {
    const userRole = getUserRole(user)

    const isOnHold = parcel?.packageStatus === packageStatusEnum.ON_HOLD_MANIFEST ||
        parcel?.packageStatus === packageStatusEnum.ON_HOLD_REMEDIATION;

    const allowEdit = userRole !== roleConstantEnum?.ROLE_CLIENT && (parcel?.packageStatus === packageStatusEnum.ON_HOLD_MANIFEST ||
        parcel?.packageStatus === packageStatusEnum.ORDER_BATCH_READY)

    return { isOnHold, allowEdit };
};

export const getTrackingNumber = (data) => {
    const trackingNumbers = data?.map((item) => {
        return { label: item?.trackingNumber, value: item?.trackingNumber };
    })
    trackingNumbers?.sort((a, b) => a.label - b.label);
    return trackingNumbers || []
}

export const checkIfLocationUpdateAllow = (parcel) => {
    return [packageStatusEnum?.LAT_LON_RESOLVE_FAILED_FROM_HERE, packageStatusEnum?.RTERMINAL_INVTGN, packageStatusEnum?.ARCHIVE, packageStatusEnum?.DAMAGED, packageStatusEnum?.OUT_OF_SERVICE_AREA]?.includes(parcel?.packageStatus)
}

export const isValidLatLng = (lat, lng) => {
    const isValidLat = typeof lat === 'number' && lat >= -90 && lat <= 90;
    const isValidLng = typeof lng === 'number' && lng >= -180 && lng <= 180;
    const isNotZeroZero = !(lat === 0 && lng === 0);
    return isValidLat && isValidLng && isNotZeroZero;
};