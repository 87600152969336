import React from "react";
import { useDrag } from "react-dnd";
import ItemTypes from "./ItemTypes";
import { BoxLable } from "./Tote";
import { styled } from "styled-components";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { urls } from "utils/urls";
import { setPackageFilter } from "features/packages/packagesSlice";

const SmallBoxContainer = styled.div`
cursor: move;
width: 50px;
height: 20px;
background-color: #3498db;
margin: 2px;
display: flex;
align-items: center;
justify-content: center;
`

const SmallBox = ({smallBox}) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.SMALL_BOX,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging()
    })
  });

  const handleNavigation = (params) => {
    const { toteId } = params;
        dispatch(setPackageFilter({ toteId: String(toteId) }));
        navigate(urls?.PARCEL_WIDGET_URL);  
};

  return (
    <SmallBoxContainer
      ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
      }}
    >
      <BoxLable onClick={() => handleNavigation({ toteId: smallBox})}>{smallBox}</BoxLable>
    </SmallBoxContainer>
  );
}

export default SmallBox;
